export function  siteCodeToName(siteCode: string) : string{
  switch (siteCode) {
    case 'DGS':
      return 'Donges';
    case 'GONF':
      return 'Normandie';
    default:
      return siteCode;
  }
}
