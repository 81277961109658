/* eslint-disable @typescript-eslint/no-explicit-any */
import { Slot } from '../macro-slot.interface';
import { Unavailability } from '../unavailability.interface';

export interface UnavailabilityCardInput {
  day: Date;
  docks: ItemUnavailabilityCardInput[];
  grades: ItemUnavailabilityCardInput[];
}

export interface ItemUnavailabilityCardInput {
  item: any;
  macroSlotsUnavailability: MacroSlotUnavailabilityCardInput[];
}

export interface MacroSlotUnavailabilityCardInput {
  microSlot: Slot;
  unavailabilities: Unavailability[];
}

export interface ItemType {
  type: ITEM_CLASS_TYPE;
  itemAttrName: string;
  unavailabilityAttrName: string;
  cardInputItemsAttrName: string;
  saveAttrName: string;
}

export enum ITEM_CLASS_TYPE {
  DOCK='DockUnavailability', GRADE='GradeUnavailability'
}

export const ITEM_TYPE_SET = {
dock : { type: ITEM_CLASS_TYPE.DOCK, itemAttrName: 'name', unavailabilityAttrName: 'dockName', cardInputItemsAttrName: 'docks', saveAttrName: 'docks' } as ItemType,
grade: { type: ITEM_CLASS_TYPE.GRADE, itemAttrName: 'grade', unavailabilityAttrName: 'gradeName', cardInputItemsAttrName: 'grades', saveAttrName: 'products' } as ItemType
};

export type ITEM_TYPE_SET = typeof ITEM_TYPE_SET

export enum ITEM_OPERATION_TYPE {
  CREATE, UPDATE, DELETE
}


export interface ItemOutputResponse {
  item: any;
  itemType: ItemType;
  operationType: ITEM_OPERATION_TYPE;
}
