import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/security/authentication.service';

@Injectable()
export class RefineryCodeInterceptor implements HttpInterceptor {
    constructor(private readonly authenticationService: AuthenticationService) {}
    private assignedRole: string | null = null;

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      const assignedRole = this.getAssignedRole();

      if (!assignedRole) {
          return next.handle(request);
      }

      const urlWithRefinery = request.url.replace(
        '/api/',
        `/api/${assignedRole}/`
      );
      const modifiedRequest = request.clone({ url: urlWithRefinery });

      return next.handle(modifiedRequest);
    }

   getAssignedRole(): string | null {
    if (!this.assignedRole) {
      const selectedSite = localStorage.getItem('selectedSite');
      if (selectedSite) {
        this.assignedRole = selectedSite;
      } else {
        const roles = this.authenticationService.getcurrentUserRoles();
        const sortedRoles = roles
          ?.map(role => role.split('_')[0])
          .sort((a, b) => a.localeCompare(b));
        this.assignedRole = sortedRoles && sortedRoles.length > 0 ? sortedRoles[0] : 'DGS';
      }
    }
    return this.assignedRole;
  }
}
